// EditPANModal.tsx
import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Space, Select, message } from "antd";
import { apiPost, apiPut } from "app/services/apiServices";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { API_ENDPOINT_EDIT_PAN_IN_WORKSPACE } from "app/shared/MasterLayout/masterlayout.constant";

interface EditPANModalProps {
  isVisible: boolean;
  selectedRow: any;
  onCancel: () => void;
}

const EditPANModal: React.FC<EditPANModalProps> = ({
  isVisible,
  selectedRow,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pan, setPan] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  useEffect(() => {
    if (selectedRow) {
      setPan(selectedRow.pan || ""); // Set pan from selectedRow
      form.setFieldsValue(selectedRow); // Pre-fill form values
    }
  }, [selectedRow, form]);

  const isValidPAN = (pan: string) => {
    const panPattern = /^[A-Z0-9]{10}$/i;
    return panPattern.test(pan);
  };

  const onFinish = async (values: any) => {
    if (!isValidPAN(pan)) {
      message.error(
        "Please enter a valid PAN number (10 alphanumeric characters)."
      );
      return;
    }

    setIsLoading(true);

    const selectedEntity = (userDetails?.pans || []).find(
      (item: any) => item.entity_id === values.entity_name
    );
    console.log("Selected Entity in dropdown: ", selectedEntity, "Selected Row: ", selectedRow);
    const updatedEntityName = selectedEntity ? selectedEntity.entity_name : selectedRow.entity_name;
    const updatedEntityId = selectedEntity ? selectedEntity.entity_id : selectedRow.entity_id;


    const payload = {
    ...values,
    oldPan: selectedRow.pan,
    oldEntityId: selectedRow.entity_id,
    entity_name: updatedEntityName,
    workspaceId: updatedEntityId,
  };

  // console.log("Edit PAN Payload: ", payload)

    try {
      // Add your PAN update API endpoint here
      const response = await apiPost(
        API_ENDPOINT_EDIT_PAN_IN_WORKSPACE,
        payload
      );

      if (response.status) {
        message.success("PAN details updated successfully");
        // Pan object for change
        const updatedPan = {
          pan:response.data?.pan,
          entity_id: response.data?.entity_id,
          entity_name: updatedEntityName,
          box_folder_id: response.boxId?.id,
          oldpan: selectedRow.pan,
          oldentity_id: selectedRow.entity_id,
          oldentityname: selectedRow.entity_name
        }
        console.log("Updated Pan object ", updatedPan)
        setUserDetails((prev :any)=> ({
          ...prev,
          pans: prev?.pans.map( (pan:any)=> 
            pan.pan === updatedPan.oldpan && pan.entity_id === updatedPan.oldentity_id // Identify the row to edit
            ? {...pan, pan: updatedPan.pan, entity_name: updatedPan.entity_name, entity_id: updatedPan.entity_id, box_folder_id: updatedPan.box_folder_id }
            : pan
          )
        }))

        onCancel();
      } else if(!response.status) {
        if (response?.error_code === "404") {
          console.log("PAN ALREADY EXISTS")
          message.error(`An Existing workspace with ${response?.error} PAN Number already exists, Please add a unique pan`);
        }
      }
    } catch (error) {
      console.log("Pan Update Error due to: ", error)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Edit PAN Details"
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      cancelButtonProps={{
        style: { color: "#74003B", border: "1px solid #74003B" },
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={selectedRow}
      >
        <Form.Item label="PAN" name="pan">
          <Input value={pan} onChange={(e: any) => setPan(e.target.value)} />
        </Form.Item>
        <Form.Item label="Entity Name" name="entity_name">
          <Select
            placeholder="Select a person"
            optionFilterProp="label"
            options={Array.from(
              new Map(
                (userDetails?.pans || []).map((pan: any) => [pan.entity_name, pan])
              ).values()
            ).map((uniquePan: any) => ({
              value: uniquePan.entity_id,
              label: uniquePan.entity_name,
            }))}
          />
        </Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ background: "#74003B" }}
          >
            Submit
          </Button>
          <Button
            htmlType="button"
            onClick={onCancel}
            style={{ color: "#74003B", border: "1px solid #74003B" }}
          >
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditPANModal;
