import React, { useEffect, useState } from "react";
import MainImage from "../../assets/images/form-1&3.png";
import "./AddCompany.scss";
import { Button, Input,Form, message } from "antd";
import ShieldIcon from "../../assets/images/shield-tick-safe (1).png";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE } from "app/shared/MasterLayout/masterlayout.constant";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined  
} from '@ant-design/icons';


const AddPanComponent: React.FC = () => {
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [PAN, setPAN] = useState("");
  // const [linkedinProfileLink, setLinkedinProfileLink] = useState('');
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [form] = Form.useForm();
  

  useEffect(() => {
      const areFieldsFilled =
        name.trim() !== "" && website.trim() !== "" && PAN.trim() !== "";
      setButtonDisabled(!areFieldsFilled);
    }, [name, website, PAN]);

  const isValidPAN = (pan: string) => {
    const panPattern = /^[A-Z0-9]{10}$/i;
    return panPattern.test(pan);
  };

  const handleSubmit = async () => {
      if (!name.trim()) {
        message.error("Company name is required.");
        return;
      }
  
      // if (!isValidWebsite(website)) {
      //   message.error("Please enter a valid website (e.g., .com, .in).");
      //   return;
      // }
  
      if (!isValidPAN(PAN)) {
        message.error(
          "Please enter a valid PAN number (10 alphanumeric characters)."
        );
        return;
      }
  
      setLoading(true);
      // Handle form submission logic
      const operationType =
        userDetails?.workspaceList?.length > 0 ? "UPDATE" : "CREATE";
  
      const payload = {
        company: name,
        pan: PAN,
        website: website,
        type: userDetails.workspaceList?.filter((workspace:any)=> workspace.company === name)[0]?.type || "PARTNER",
        operationType: operationType,
        entity_type: userDetails.workspaceList?.filter((workspace:any)=> workspace.company === name)[0]?.entity_type || "CORPORATE",
      };
  
      const response = await apiPost(
        API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE,
        payload
      );
      if (response.status) {
        // if (operationType !== "UPDATE") {
        const newWorkspace = response?.data
        // setOperationType('UPDATE');

        const newPanObj = {
          pan: response?.data.pan,
          entity_id: response?.data?.id,
          entity_name: response?.data?.name,
          box_folder_id : response?.data.box_folder_id,
          last_updated: response?.data.last_updated
        };

        setUserDetails((prev: any) => ({
          ...prev,
          company: name,
          pan: PAN,
          website: website,

          pans: prev.pans?.some((pan: any) => pan.pan === newPanObj.pan)
            ? prev.pans?.map((pan:any) => 
                pan.pan === newPanObj.pan 
                  ? {...pan, last_updated: newPanObj.last_updated }
                  : pan)
            : [...(prev.pans || []), newPanObj],

          workspaceList: prev.workspaceList?.some(
            (workspace: any) => workspace.id === newWorkspace.id
          )
            ? prev.workspaceList.map((workspace: any) =>
                workspace.id === newWorkspace.id
                  ? { ...workspace, ...newWorkspace }
                  : workspace
              )
            : [...(prev.workspaceList || []), newWorkspace]
        }));
        // } else {
        //   setUserDetails((prev: any) => ({
        //     ...prev,
        //     company: name,
        //     pan: PAN,
        //     website: website,
        //   }));
        // }
        navigate(-1);
        form
          .validateFields()
          .then(() => {
            // onNext(); // Trigger the next step
            message.success("Company details updated");
          })
          .catch((info) => {
            console.error("Validation Failed:", info);
          });
      } else if (!response.status) {
        // Handle Non unique company or pan name
        if (response?.error_code === "404") {
          console.log("WORKSPACE ALREADY EXISTS")
          message.error(`Workspace: ${response?.error} Name already exists, Please use a unique Workspace Name`);
        } else if (response?.error_code === "402") {
          console.log("PAN ALREADY EXISTS")
          message.error(`PAN: ${response?.error} already exists, Please enter Unique PAN`);
        }      
      } else {
        message.error("Could not update company details");
      }
      setLoading(false);
    };

    const handleGoBack = () => {
      navigate(-1); // Navigate back to the previous page
    };

  return (
    <>
    <ArrowLeftOutlined style={{color:'#74003B', fontSize:'26px', padding:'15px 25px 0px',cursor: "pointer",}} onClick={handleGoBack}/>
    <div className="company-details-form">
      <Form
        form={form}
        layout="vertical"
        initialValues={{ name: name }}
        fields={[
          {
            name: "name",
            value: name,
          },
          {
            name: "Company Website",
            value: website,
          },
          {
            name: "PAN Number",
            value: PAN,
          },
        ]}
      >
        <div className="form-header">Enter Company Details</div>

        <Form.Item
          label="Company name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input
            type="text"
            defaultValue={name}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter company name"
            style={{ borderColor: "#C3C4CD" }}
          />
        </Form.Item>

        <Form.Item
          label="Company Website"
          name="Company Website"
          rules={[
            { required: true, message: "Please input your company website!" },
          ]}
        >
          <Input
            type="text"
            defaultValue={website}
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            placeholder="Enter company website"
            style={{ borderColor: "#C3C4CD" }}
          />
        </Form.Item>

        <Form.Item
          label="PAN Number"
          name="PAN Number"
          rules={[{ required: true, message: "Please input your PAN Number!" }]}
        >
          <Input
            type="text"
            defaultValue={PAN}
            value={PAN}
            onChange={(e) => setPAN(e.target.value)}
            placeholder="Enter PAN Number"
            style={{ borderColor: "#C3C4CD" }}
          />
        </Form.Item>

        <div className="info-text">
          <img src={ShieldIcon} alt="shield icon" />
          <p>No spams your information is safe with us!</p>
        </div>

        <Button
          type="primary"
          className="tax-manager-submit-button"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
          loading={isLoading}
          style={{
            backgroundColor: isButtonDisabled ? "#A8A8A8" : "#74003B",
            borderColor: isButtonDisabled ? "#A8A8A8" : "#74003B",
          }}
        >
          Next
        </Button>
      </Form>
    </div>
    </>
  );
};

export default AddPanComponent;
