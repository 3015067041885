import React from "react";
import { ConfigProvider } from "antd";
import { RecoilRoot } from "recoil";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
import Entry from "./Entry";
import "../src/theme/index.scss";
import "./App.css";

const App: React.FC = () => {
  const defaultData = {
    colorPrimary: "#74003B",
  };
  return (
    <IntlProvider locale="en" defaultLocale="en">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: defaultData.colorPrimary,
          },
        }}
      >
        <RecoilRoot>
          <Router>
            <Entry />
          </Router>
        </RecoilRoot>
      </ConfigProvider>
    </IntlProvider>
  );
};

export default App;
