import React from 'react';
import './HeroSection.scss';
import LandingPageImg from '../../../assets/images/qatar-landing-hero.jpeg';
import Clouds from '../../../assets/images/8918206.png';
import { Statistic } from 'antd';
import CountUp from 'react-countup';

// Formatter Function for Statistics
const formatter = (value: number, suffix: string = '') => (
  <CountUp end={value} separator="," formattingFn={(val) => `${val.toLocaleString()}${suffix}`} />
);

// Reusable Statistic Item Component
interface StatItemProps {
  title: string;
  value: number;
  suffix?: string;
}

const StatItem: React.FC<StatItemProps> = ({ title, value, suffix = '' }) => (
  <div className="stat-item">
    <Statistic
      title={<span className="custom-statistic-title">{title}</span>}
      value={value}
      formatter={(val) => formatter(val as number, suffix)}
      className="custom-statistic-value"
    />
  </div>
);

// Main Component
const GSTInvoiceRetrieval = () => {
  return (
    <div className="gst-invoice-container" id="hero-section">
      {/* Hero Image Section */}
      <div className="invoice-image-container">
        <img className="invoice-image" src={LandingPageImg} alt="Qatar Airways Invoice Sample" />
        <img className="hero-clouds" src={Clouds} alt="Clouds" />
      </div>

      {/* Content Section */}
      <div className="content">
        <h1>Effortlessly Retrieve GST Invoices from Qatar Finkraft Portal</h1>
        <p>
          Let Finkraft handle your GST input, so your employees can focus on
          business. With Finkraft ensuring Qatar Airways' invoices are GST
          compliant, your team can travel with peace of mind.
        </p>

        {/* Stats Section */}
        <div className="stats">
          <StatItem title="Invoices Download" value={80} suffix=" M" />
          <StatItem title="Number of Users" value={200} suffix="+" />
          <StatItem title="Years of Service" value={15} suffix="+" />
        </div>
      </div>
    </div>
  );
};

export default GSTInvoiceRetrieval;
