import React, { useEffect, useState } from "react";
import Header from "../../../shared/PageHeader";
import Footer from "../../../shared/PageFooter";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import LogoFk from "../../../assets/images/logo FK.png";
import "../RegisterNow/Register.scss";
import RegisterBg from "../../../assets/images/LoginRegisterBg.png";
import InvoiceFeatures from "../../../shared/InvoiceFeatures";
import FAQSection from "../../../shared/FAQ";
import HelpSection from "../../../shared/HelpSection";
import GoogleIcon from "../../../assets/images/Google.png";
import LinkedInIcon from "../../../assets/images/linkedin.png";
import Clouds from "../../../assets/images/8918206.png";
import { useNavigate } from "react-router-dom";
import { Input, Modal, message, Button } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_AUTH_SIGNIN,
  API_ENDPOINT_META_USER_INFO,
} from "app/scenes/Auth/auth.constant";
import { useRecoilState } from "recoil";
import { userInfo, workspaceInfo } from "app/config/States/users";

{
  /* <EyeInvisibleOutlined /> */
}

function RegisterPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [otpEntered, setOtpEntered] = useState(false);
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);
  const [mobileOtpVerified, setMobileOtpVerified] = useState(false);
  const [mobileModalOpen, setMobileModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  // const [isButtonDisabled, setButtonDisabled] = useState(true);

  const navigate = useNavigate();

  const handleOtpChange = (text: string, type: "email" | "mobile") => {
    if (text === "999999") {
      if (type === "email") {
        setEmailOtpVerified(true);
        setEmailModalOpen(false);
        message.success("Email verified successfully");
      } else {
        setMobileOtpVerified(true);
        setMobileModalOpen(false);
        message.success("Mobile number verified successfully");
      }
      navigate("/app/userdetails");
    } else if (text.length === 6) {
      message.error("Invalid OTP");
    }
  };

  const EmailModal = () => (
    <Modal
      title="Verify by Email ID"
      style={{ fontSize: "24px", fontWeight: "600" }}
      centered
      open={emailModalOpen}
      onOk={() => setEmailModalOpen(false)}
      onCancel={() => setEmailModalOpen(false)}
      okText="Verify"
      okButtonProps={{
        style: {
          backgroundColor: "var(--primary-color)",
          color: "white",
          borderRadius: "5px",
          width: "30%",
          display: "flex",
        },
        className: "custom-ok-button",
      }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <p style={{ fontSize: "16px", fontWeight: "400" }}>
        We've sent a verification code on
      </p>
      <p style={{ fontSize: "20px", fontWeight: "600", lineHeight: "1" }}>
        {email}
      </p>
      <Input.OTP
        variant="filled"
        onChange={(value) => handleOtpChange(value, "email")}
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter') {
        //     handleOtpChange( value, 'email');
        //   }
        // }}
        length={6}
      />
      <p style={{ fontSize: "16px", fontWeight: "400" }}>
        Did not receive any OTP? <a href="#">Resend OTP</a>
      </p>
    </Modal>
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fetchUserLogin = async () => {
    const response = await apiGet(API_ENDPOINT_META_USER_INFO);
    console.log("response for meta user info:", response);
    if (response.status) {
      setUserDetails({
        ...response.data,
        currentWorkspace: workspaceInfo,
      });
      localStorage.setItem(
        "currentWorkspaceId",
        workspaceInfo.id
      );
      navigate("/app");
      // fetchUserLogin();
    } else {
      message.error("Invalid credential");
    }
    setLoading(false);
  };

  console.log("user details saved are:", userDetails);
  const handleLogin = async () => {
    setLoading(true);
    const payload = {
      email: email,
      password: password,
    };

    const respponse = await apiPost(API_ENDPOINT_AUTH_SIGNIN, payload);
    console.log("respponse:", respponse);

    if (respponse.status) {
      localStorage.setItem("token", respponse.data.token);

      await fetchUserLogin();
    } else {
      message.error("Invalid credential");
    }
  };

  return (
    <div className="register-page" id="registration-page">
      <div className="header">
        <Header />
      </div>
      <div className="register-hero">
        <img
          className="register-hero-bg"
          src={RegisterBg}
          alt="Person reviewing invoice"
        />
        {/* <div className="register-hero-filter"></div> */}
        <img className="hero-clouds" src={Clouds} alt="Clouds" />
        <div className="login-container">
          <div className="login-left">
            <div className="login-left-content">
              <img className="logo-fk" src={LogoFk} alt="" />
              <h2>Welcome To Our</h2>
              <h1>Qatar Airways Portal</h1>
              <p>
                Your one stop solution to download tax invoices for Corporates
                TMCs, OTAs and Cargo
              </p>
            </div>
          </div>
          <div
            style={{
              height: "100%",
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="login-right">
              <div className="login-form-container">
                <h2>Log In</h2>
                <p className="signup-prompt">
                  Don't have an account?{" "}
                  <a href="#" onClick={() => navigate("/register")}>
                    {" "}
                    Sign Up
                  </a>
                </p>
                <form
                  className="login-form"
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent the default form submission behavior
                    handleLogin(); // Call the function to log the payload
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      defaultValue=" "
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email address"
                      style={{ borderColor: "#C3C4CD" }}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="password">Password</label>

                    <Input.Password
                      type="text"
                      value={password}
                      defaultValue=" "
                      placeholder="input password"
                      onChange={(e) => setPassword(e.target.value)}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      style={{
                        borderColor: "#C3C4CD",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        margin: "8px 0px",
                      }}
                    />
                  </div>
                  {/* <div className="form-options">
                  <div className="remember-me">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">Remember me</label>
                  </div>
                  <a href="#" className="forgot-password">
                    Forgot password?
                  </a>
                </div> */}
                  {/* <button
                  type="submit"
                  className="submit-button"
                  onClick={handleLogin}
                  loading={isLoading}
                >
                  Sign In
                </button> */}
                  <Button
                    type="primary"
                    onClick={handleLogin}
                    loading={isLoading}
                    style={{
                      backgroundColor: "#74003B",
                      borderColor: "#74003B",
                      color: "#ffffff",
                      height: "40px",
                      width: "100%",
                      margin: "33px 0px",
                    }}
                  >
                    Sign In
                  </Button>
                </form>
                {/* <div
                className="divider"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  fontSize: "19px",
                  color: "#666",
                }}
              >
                {" "}
                — OR —{" "}
              </div> */}
                {/* <div className="social-login">
                <button className="social-button">
                  <img src={GoogleIcon} alt="Google" /> <p>Google</p>
                </button>
                <button className="social-button">
                  <img src={LinkedInIcon} alt="LinkedIn" /> <p>LinkedIn</p>
                </button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmailModal />

      <div className="invoice-feature-section">
        <InvoiceFeatures />
      </div>
      <div className="faq-section">
        <FAQSection />
      </div>
      <div className="help-section">
        <HelpSection />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default RegisterPage;
