import React, { useState , useEffect} from 'react';
import { Button, Modal, Form, Input, Checkbox, message } from 'antd';
import type { FormProps } from 'antd';
import { apiPost, apiGet } from "app/services/apiServices";
import "./AddPan.scss";
import { API_ENDPOINT_ONBOARDING_ADD_PAN_TO_WORKSPACE } from "app/shared/MasterLayout/masterlayout.constant";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined  
} from '@ant-design/icons';


type FieldType = {
  company?: string;
  website?: string;
  pan?: string;
};

const AddPanModal: React.FC = ()=> {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [pan, setPan] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const navigate = useNavigate();
  const [form] = Form.useForm(); 
    

  useEffect(() => {
      const areFieldsFilled =
        company.trim() !== "" && website.trim() !== "" && pan.trim() !== "";
      setButtonDisabled(!areFieldsFilled);
  }, [company, website, pan]);

  const isValidPAN = (pan: string) => {
    const panPattern = /^[A-Z0-9]{10}$/i;
    return panPattern.test(pan);
  };

  const handleOk = async ()=> {

    if (!isValidPAN(pan)) {
      message.error(
        "Please enter a valid PAN number (10 alphanumeric characters)."
      );
      return;
    }

    setLoading(true);
    
    setConfirmLoading(true);
    const payload = {
      pan: pan,
      workspaceId: userDetails?.workspaceInfo?.id,
    };
    try {
          const response = await apiPost(
            API_ENDPOINT_ONBOARDING_ADD_PAN_TO_WORKSPACE,
            payload
          );
          console.log("API RESPONSE: ", response)
          if (response.status) {
            const newPanObj = {
              pan: response?.data.pan,
              entity_id: response?.data?.entity_id,
              entity_name: userDetails?.workspaceInfo?.company,
              last_updated: response?.data.last_updated,
              box_folder_id: response?.boxId?.id
            };

            setUserDetails((prev: any) => ({
              ...prev,
              pan:pan,
              pans: prev.pans?.some((pan: any) => pan.pan === newPanObj.pan)
              ? prev.pans?.map((pan:any) => 
                  pan.pan === newPanObj.pan 
                    ? {...pan, ...newPanObj }
                    : pan)
              : [...(prev.pans || []), newPanObj],
            }));
            message.success("Pan added succesfully");
            navigate("/app/dashboard");
            // onClose();
          } else if(!response.status) {
            if (response?.error_code === "404") {
              console.log("PAN ALREADY EXISTS")
              message.error(`An Existing workspace with ${response?.error} PAN Number already exists, Please add a unique pan`);
            }
          }
          console.log("User Details after Add Pan: ",userDetails)
        } catch (error) {
          message.error("Could not add pan");
    
          console.error("Error:", error);
        } finally {
          setLoading(false);
        }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  // const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
  //   console.log('Success:', values);
  //   handleOk(values); // Close modal after successful form submission
  // };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <ArrowLeftOutlined style={{color:'#74003B', fontSize:'26px', padding:'15px 25px 0px',cursor: "pointer",}} onClick={handleGoBack}/>
      <div className="company-details-form">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="form-header"> Add Pan</div>

          {/* <Form.Item<FieldType>
            label="Company Name"
            name= "company"
            rules={[{ required: true, message: 'Please input your company name!' }]}
          >
            <Input
            type="text"
            onChange={(e) => setCompany(e.target.value)}
             />
          </Form.Item>

          <Form.Item<FieldType>
            label="Company website"
            name="website"
            rules={[{ required: true, message: 'Please input your company website!' }]}
          >
            <Input
            type="text" 
            onChange={(e) => setWebsite(e.target.value)}
            />
          </Form.Item> */}

          <Form.Item<FieldType>
            label="PAN Number"
            name="pan"
            rules={[{ required: true, message: 'Please input your PAN Number!' }]}
          >
            <Input 
              type="text"
              onChange={(e) => setPan(e.target.value)}
            />
          </Form.Item>

          <Form.Item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="primary"
                        color="primary"
                        htmlType="submit"
                        loading={isLoading}
                        onClick={handleOk}
                      >
                        Submit
                      </Button>
                    </Form.Item>
        </Form>
        </div>
      {/* </Modal> */}
    </>
  );
};

export default AddPanModal;