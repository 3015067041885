import React, { useState } from "react";
import { Menu, Dropdown, Input, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Typography from "../Typography";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import ExpandMoreRoundedIcon from "@ant-design/icons";

const { Search } = Input;

const workspaceColorMap = [
  "#4555eb",
  "#209f85",
  "#fa694a",
  "#2c3e50",
  "#e74c3c",
];

const PickWorkspaceColor = (word) => {
  if (!word) return "#cccccc"; // Fallback color
  const firstChar = word.charAt(0)?.toLowerCase();
  const alphabetIndex = firstChar.charCodeAt(0) - "a".charCodeAt(0);
  const colorIndex = Math.floor(
    alphabetIndex / (26 / workspaceColorMap.length)
  );
  return workspaceColorMap[colorIndex] || "#cccccc";
};

function formatString(str) {
  // return str?.charAt(0).toUpperCase() + str?.slice(1)?.toLowerCase();
  return str
}

const SearchDropdown = ({ workspaceList, handleSelectWorkspce, userData }) => {
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(
    userData?.workspaceInfo || null
  );
  const location = useLocation(); // Get the current location

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const filteredItems = (workspaceList || []).filter((item) =>
    item.company?.toLowerCase().includes(searchText?.toLowerCase())
  );

  const handleClick = (workspace) => {
    // console.log("Selected Workspace: ", workspace)
    // Update the selected workspace
    setSelectedWorkspace(workspace);

    // Call the parent component's handler
    handleSelectWorkspce(workspace);

    // Close the dropdown
    setOpen(false);
  };
  // console.log("workspaceList", workspaceList);
  const menu = (
    <Menu>
      <Menu.Item key="search">
        <Input
          placeholder="Search workspaces"
          onChange={(e) => handleSearch(e.target.value)}
          onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing on input click
          style={{ height: 32 }}
        />
      </Menu.Item>
      {filteredItems.map((element) => (
        <Menu.Item
          key={element.id}
          onClick={() => handleClick(element)}
          style={{
            backgroundColor:
              selectedWorkspace?.id === element.id ? "#f0f0f0" : "transparent",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: 170,
              cursor: "pointer",
            }}
          >
            <Avatar
              size={22}
              style={{
                backgroundColor: PickWorkspaceColor(element.company),
                color: "#ffffff",
                marginRight: 6,
              }}
            >
              <Typography variant="xs" style={{ color: "white" }}>
                {`${element.company?.[0]?.toUpperCase()}${element.company?.[1]?.toUpperCase()}`}
              </Typography>
            </Avatar>
            <p
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                color: "var(--text-color-black)",
                fontSize: 12,
              }}
            >
              {formatString(element.company)}
            </p>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  if (location.pathname === "/app") {
    return null; // Do not render the dropdown on the '/app' page
  }

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      open={open}
      onOpenChange={setOpen}
      style={{ height: "50px" }}
    >
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Avatar
          size={24}
          style={{
            backgroundColor: PickWorkspaceColor(
              userData?.currentWorkspace?.name ||
              userData?.currentWorkspace?.company
            ),
            color: "#ffffff",
            marginRight: 6,
          }}
        >
          {
            (
              userData?.currentWorkspace?.name ||
              userData?.currentWorkspace?.company
            )?.toUpperCase()?.[0]
          }
        </Avatar>
        <Typography variant="caption">
          {formatString(
            userData?.currentWorkspace?.name ||
            userData?.currentWorkspace?.company
          )}
        </Typography>
        <DownOutlined style={{ fontSize: "12px", marginLeft: "5px" }} />
      </div>
    </Dropdown>
  );
};

export default SearchDropdown;
