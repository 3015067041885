import React, { useCallback, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './AgGrid.scss';
import 'ag-grid-enterprise';
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";

import { 
  ColDef, 
  GridReadyEvent, 
  GridApi, 
  ValueGetterParams,
  ICellRendererParams 
} from 'ag-grid-enterprise';

// Define column types for type safety
export type ColumnType = 'text' | 'number' | 'date' | 'boolean' | 'currency' | 'custom';

// Define valid filter types
export type FilterType = boolean | string;

// Enhanced column definition interface
export interface ColumnDefinition {
  headerName: string;
  field: string;
  type?: ColumnType;
  sortable?: boolean;
  filter?: FilterType;
  width?: number;
  flex?: number;
  cellRenderer?: React.ComponentType<ICellRendererParams> | string;
  valueGetter?: (params: ValueGetterParams) => any;
  cellStyle?: any;
  headerClass?: string;
  formatConfig?: {
    currency?: string;
    dateFormat?: string;
    numberFormat?: string;
    customFormat?: (value: any) => string;
  };
  enableRowGroup?: boolean
 
}

// Column preset configurations
export const columnPresets: Record<ColumnType, Partial<ColumnDefinition>> = {
  text: {
    filter: 'agTextColumnFilter',
    sortable: true,
  },
  number: {
    filter: 'agNumberColumnFilter',
    sortable: true,
    cellStyle: { textAlign: 'right' },
  },
  date: {
    filter: 'agDateColumnFilter',
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      if (!params.data[params.column.getColDef().field!]) return '';
      return new Date(params.data[params.column.getColDef().field!]);
    },
  },
  boolean: {
    filter: 'agSetColumnFilter',
    sortable: true,
    width: 100,
  },
  currency: {
    filter: 'agNumberColumnFilter',
    sortable: true,
    cellStyle: { textAlign: 'right' },
    valueGetter: (params: ValueGetterParams) => {
      const value = params.data[params.column.getColDef().field!];
      const formatConfig = (params.column.getColDef() as ColumnDefinition).formatConfig;
      if (!value) return '';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: formatConfig?.currency || 'USD',
      }).format(value);
    },
  },
  custom: {},
};

// Helper function to create column definitions
export const createColumnDef = (
  column: ColumnDefinition
): ColDef => {
  const type = column.type || 'text';
  const preset = columnPresets[type];
  
  return {
    ...preset,
    ...column,
    valueGetter: column.valueGetter || preset.valueGetter,
  };
};

export interface CustomGridProps {
  columnDefs: ColumnDefinition[];
  rowData: any[];
  rowHeight?: number;
  headerHeight?: number;
  paginationPageSize?: number;
  defaultSortField?: string;
  defaultSortOrder?: 'asc' | 'desc';
  onRowSelected?: (event: any) => void;
  onCellClicked?: (event: any) => void;
  onGridReady?: (params: GridReadyEvent) => void;
  className?: string;
  style?: React.CSSProperties;
  enableCheckbox?: boolean;
  enablePagination?: boolean;
  enableFilter?: boolean;
  enableSort?: boolean;
  frameworkComponents?: { [key: string]: React.ComponentType<any> };
}

const CustomAgGrid: React.FC<CustomGridProps> = ({
  columnDefs,
  rowData,
  rowHeight = 48,
  headerHeight = 48,
  paginationPageSize = 10,
  defaultSortField,
  defaultSortOrder = 'asc',
  onRowSelected,
  onCellClicked,
  onGridReady: parentOnGridReady,
  className = '',
  style = {},
  enableCheckbox = true,
  enablePagination = true,
  enableFilter = true,
  enableSort = true,
  frameworkComponents = {}
}) => {
  const gridApiRef = React.useRef<GridApi | null>(null);

  // Process column definitions with presets
  const processedColumnDefs = useMemo(() => {
    const cols: ColDef[] = [];
    
    if (enableCheckbox) {
      cols.push({
        headerName: '',
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        pinned: 'left',
        lockPosition: true
      });
    }

    // Apply column presets and configurations
    columnDefs.forEach(col => {
      cols.push(createColumnDef(col));
    });

    return cols;
  }, [columnDefs, enableCheckbox]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
    suppressMovable: true,
    sortable: enableSort,
    filter: enableFilter
  }), [enableSort, enableFilter]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    params.api.sizeColumnsToFit();

    if (parentOnGridReady) {
      parentOnGridReady(params);
    }
  }, [parentOnGridReady]);

  return (
    <div 
      className={`ag-theme-custom ${className}`}
      style={{ 
        width: '100%',
        height: '400px',
        ...style
      }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={processedColumnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        rowGroupPanelShow='always'
        pagination={enablePagination}
        paginationPageSize={paginationPageSize}
        rowSelection={enableCheckbox ? "multiple" : undefined}
        suppressRowClickSelection={enableCheckbox}
        enableCellTextSelection={true}
        animateRows={true}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        suppressContextMenu={true}
        onRowSelected={onRowSelected}
        onCellClicked={onCellClicked}
      />
    </div>
  );
};

export default CustomAgGrid;
