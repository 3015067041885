import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./PrimaryNav.scss";
import QatarLogo from "../../assets/images/qatar-airways-1 (1) 1.png";
import {
  FileOutlined,
  UserOutlined,
  FileDoneOutlined,
  PieChartOutlined,
  DollarOutlined,
  MailOutlined,
} from "@ant-design/icons";
import DashboardIcon from "../../assets/images/SquaresFour.png";
import FileIcon from "../../assets/images/File.png";
import UserIcon from "../../assets/images/UserCircle.png";
import InvoiceRequestIcon from "../../assets/images/Invoice.png";
import DashboardInactiveIcon from "../../assets/images/dashboard-inactive.png";
import UserActiveIcon from "../../assets/images/UserCircle-active.png";
import InvoiceInactiveIcon from "../../assets/images/InvoiceInactiveIcon.png";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import UserIconColored from "../../assets/images/UserCircle (1).png";
import { Button, Dropdown, Divider } from "antd";
import type { MenuProps } from "antd";

export default function PrimaryNav() {
  const location = useLocation();
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userDetails?.userInfo?.email?.includes("finkraft.ai") ||
      userDetails?.userInfo?.email?.includes("kgrp.in") ||
      userDetails?.userInfo?.status === "ACCEPTED"
    ) {
      if (location.pathname === "/app") {
        navigate("/app/dashboard", { replace: true });
      }
    }
  }, [userDetails, location.pathname, navigate]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const profileItems: MenuProps = {
    items: [
      {
        key: "1",
        label: (
          <>
            <div className="UserInfo" style={{ color: "gray" }}>
              {userDetails?.userInfo?.name}
            </div>
            {/* <Divider style={{ margin: 0, marginTop: 15 }} /> */}
          </>
        ),
        disabled: true,
      },
      {
        type: "divider",
      },
      {
        key: "2",
        danger: true,
        label: (
          <div 
            onClick={handleLogout} 
            style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", width: "100%", height: "100%" }}
          >
            Logout
          </div>
        ),
      },
    ],
  };

  return (
    <div className="navbar">
      <div className="navbar-menu">
        <ul>
          {userDetails?.userInfo?.email?.includes("finkraft.ai") ||
          userDetails?.userInfo?.email?.includes("kgrp.in") ? (
            <li
              className={location.pathname === "/app/dashboard" ? "active" : ""}
            >
              <Link to="/app/dashboard">
                <PieChartOutlined
                  className="nav-icons"
                  style={{ fontSize: 24, marginBottom: 8 }}
                />
                Dashboard
              </Link>
            </li>
          ) : (
            <li className={location.pathname === "/app" ? "active" : ""}>
              <Link to="/app">
                <PieChartOutlined
                  className="nav-icons"
                  style={{ fontSize: 24, marginBottom: 8 }}
                />
                Dashboard
              </Link>
            </li>
          )}
          <li
            className={location.pathname === "/app/userdetails" ? "active" : ""}
          >
            <Link to="/app/userdetails">
              {/* <FileOutlined className="nav-icons" style={{ fontSize: 24 }}/> */}
              {/* <img
                src={
                  location.pathname === "/app/userdetails"
                    ? UserActiveIcon
                    : UserIcon
                }
                alt="icon"
              /> */}
              <UserOutlined
                className="nav-icons"
                style={{ fontSize: 24, marginBottom: 8 }}
              />
              User Details
            </Link>
          </li>
          {userDetails?.userInfo?.email?.includes("finkraft.ai") ||
          userDetails?.userInfo?.email?.includes("kgrp.in") ? (
            <li
              className={
                location.pathname === "/app/mailstatus" ? "active" : ""
              }
            >
              <Link to="/app/mailstatus">
                <MailOutlined
                  className="nav-icons"
                  style={{ fontSize: 24, marginBottom: 8 }}
                />
                Escalation Status
              </Link>
            </li>
          ) : null}

          {userDetails?.userInfo?.status === "ACCEPTED" ? (
            <li
              className={location.pathname === "/app/invoices" ? "active" : ""}
            >
              <Link to="/app/invoices">
                {/* <img
                src={
                  location.pathname === "/app/userdetails"
                    ? UserActiveIcon
                    : UserIcon
                }
                alt="icon"
              /> */}
                <FileDoneOutlined
                  className="nav-icons"
                  style={{ fontSize: 24 }}
                />
                Invoices
              </Link>
            </li>
          ) : null}
          {userDetails?.userInfo?.email?.includes("kgrp.in") ||
          userDetails?.userInfo?.email?.includes("finkraft.ai") ? (
            <li
              className={
                location.pathname === "/app/invoicerequest" ? "active" : ""
              }
            >
              <Link to="/app/invoicerequest">
                <DollarOutlined
                  className="nav-icons"
                  style={{ fontSize: 24 }}
                />
                Invoice Request
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
      <div className="profile-btn-container">
        <Dropdown menu={profileItems} trigger={["click"]} arrow>
          <Button
            className="avatar-btn profile-btn"
            style={{ zIndex: 99999, marginBottom: 0 }}
          >
            <img src={UserIconColored} alt="icon" />
            {/* <span>{userDetails?.userInfo?.name}</span> */}
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}
