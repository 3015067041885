import React, {useState, useEffect,useCallback} from 'react';
import { Button, Form, Input , message} from 'antd';
import { useRecoilState } from "recoil";
import { userInfo, operationTypeState, hardRefresh } from "app/config/States/users";
import { API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER } from "app/shared/MasterLayout/masterlayout.constant";
import { apiPost, apiGet } from "app/services/apiServices";
import ShieldIcon from "../../../../assets/images/shield-tick-safe (1).png";
import "./TravelContact.scss";

interface StepOneProps {
  onNext: () => void;
  onPrev: () => void;
}

const  TravelContact : React.FC<StepOneProps> = ({ onNext, onPrev }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  // const [linkedinProfileLink, setLinkedinProfileLink] = useState('');
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [operationType, setOperationType] = useRecoilState(operationTypeState);
  const [prevEmail, setPrevEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [form] = Form.useForm();

  // const handleSubmit = () => {
  //   form.validateFields()
  //     .then(() => {
  //       onNext(); // Trigger the next step
  //     })
  //     .catch((info) => {
  //       console.error('Validation Failed:', info);
  //     });
  // };

  console.log("userdetailstravel:", userDetails)
  useEffect(() => {
    // Prefill form fields with data from Recoil state
    
    setName(userDetails?.travel_contacts?.[0]?.name || '');
    // const initialEmail = userDetails?.travel_contacts?.[0]?.email || '';
    // setEmail(initialEmail);
    setEmail(userDetails?.travel_contacts?.[0]?.email || '');
    setMobile(userDetails?.travel_contacts?.[0]?.phone || '');
    setLinkedinProfile(userDetails?.travel_contacts?.[0]?.linkedin_profile || '');
    const initialEmail = userDetails?.travel_contacts?.[0]?.email || '';
  
    // Always set the initial email
    setPrevEmail(initialEmail);
    
    // Prevent email modification during "UPDATE"
    // if (operationType === "UPDATE") {
    //   setEmail(initialEmail);
    // } else {
    //   setEmail(initialEmail); // For "CREATE", set the email normally
    // }
    }, [userDetails,  operationType, prevEmail]);

  useEffect(() => {
      const areFieldsFilled = name.trim() !== '' && email.trim() !== '' && mobile.trim() !== '' && linkedinProfile.trim() !== '';
      setButtonDisabled(!areFieldsFilled);
    }, [name, email, mobile, linkedinProfile]);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let trimmedName = name.trim();
    let trimmedEmail = email.trim();
    let trimmedMobile = mobile.trim();
    let trimmedLinkedinProfile = linkedinProfile.trim();

  const operationType = userDetails?.travel_contacts?.length > 0 ? "UPDATE" : "CREATE";

  let payload = {
    name: trimmedName,
    email: trimmedEmail,
    phone: trimmedMobile,
    linkedin_profile: trimmedLinkedinProfile,
    workspaceId: userDetails?.workspaceList?.[0]?.id,
    type: "TRAVEL_CONTACT",
    operationType: operationType,
  };
  console.log("payload is:", payload);

  const response = await apiPost(
    API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER,
    payload
  );
  if (response.status) {
    const newTC = {
      user_id:response.userid,
      name: trimmedName,
      email: trimmedEmail, // Ensure updated email is saved
      phone: trimmedMobile,
      linkedin_profile: trimmedLinkedinProfile,
      account_type: "TRAVEL_CONTACT"
    }
    
    setUserDetails((prev: any) => ({
      ...prev,
      travel_contacts:[
        newTC,
        ...(prev.travel_contacts?.filter((tc:any)=> tc.user_id !== newTC.user_id) || [])
        ],
        tax_managers:[
        ...(prev.tax_managers?.filter((tm:any)=> tm.user_id !== newTC.user_id) || [])
        ]
      })
    );
 
    form.validateFields()
          .then(() => {
            onNext(); // Trigger the next step
            // setOperationType('UPDATE');
            message.success("Travel Contact details updated");
          })
          .catch((info) => {
            console.error('Validation Failed:', info);
          });
      } else {
        message.error("Could not update Travel Contact details");
      }
    setLoading(false);
    // Here you can handle the form submission, e.g., send the data to a backend
  };

  const handleBack = () => {
    onPrev();
  }

  return (
    <div className='travel-contact-form'>
    <Form form={form} layout="vertical"
    fields={[
      {
        name:'name',
        value:name
      },
      {
        name:'Email',
        value: email
      },
      {
        name:'Mobile',
        value: mobile
      },
      {
        name:'LinkedIn profile link',
        value: linkedinProfile
      }
    ]}>
    <div className="form-header">Travel Contact</div>

      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input your name!' }]}
      >
        <Input 
          type="text"
          defaultValue={name}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
          style={{ borderColor: "#C3C4CD" }}
         />
      </Form.Item>

      <Form.Item
        label="Email"
        name="Email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input 
          type="text"
          defaultValue={email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email address"
          style={{ borderColor: "#C3C4CD" }}
         />
      </Form.Item>

      <Form.Item
        label="Mobile"
        name="Mobile"
        rules={[{ required: true, message: 'Please input your Mobile!' }]}
      >
        <Input 
          type="text"
          defaultValue={mobile}
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          placeholder="Enter Mobile Number"
          style={{ borderColor: "#C3C4CD" }}
         />
      </Form.Item>

      <Form.Item
        label="LinkedIn profile link"
        name="LinkedIn profile link"
        rules={[{ required: true, message: 'Please input your LinkedIn profile link!' }]}
      >
        <Input 
          type="text"
          defaultValue={linkedinProfile}
          value={linkedinProfile}
          onChange={(e) => setLinkedinProfile(e.target.value)}
          placeholder="LinkedIn profile link"
          style={{ borderColor: "#C3C4CD" }}
         />
      </Form.Item>

      <div className="info-text">
            <img src={ShieldIcon} alt="shield icon" />
            <p>No spams your information is safe with us!</p>
      </div>

      <div className='btn-container'>
          <Button type="primary" onClick={handleBack} style={{width:'48%', background:'#ffffff', border:'1px solid #74003B', color:'#74003B',  height:'40px'}}>
            Back
          </Button>
          <Button type="primary" onClick={handleSubmit}   disabled={isButtonDisabled}  loading={isLoading} style={{width:'48%', backgroundColor: isButtonDisabled ? '#A8A8A8' : '#74003B', borderColor: isButtonDisabled ? '#A8A8A8' : '#74003B', color:'#ffffff',  height:'40px'}}>
              Next
          </Button>
      </div>
    </Form>
    </div>
  );
};

export default TravelContact;
