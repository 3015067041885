import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Checkbox , message} from 'antd';
import type { FormProps } from 'antd';
import { apiPost, apiGet } from "app/services/apiServices";
import "./InviteTmc.scss";
import { API_ENDPOINT_ONBOARDING_ADD_TMC_TO_WORKSPACE } from "app/shared/MasterLayout/masterlayout.constant";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import EntitySearch from "app/shared/EntitySearch";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined  
} from '@ant-design/icons';


type FieldType = {
  username?: string;
  email?: string;
  mobile?: string;
  linkedin?:string;
};

const InviteTMCModal: React.FC= ()=> {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState("");
  const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [form] = Form.useForm(); 
    const navigate = useNavigate();


   useEffect(() => {
         const areFieldsFilled =
           name.trim() !== "" && email.trim() !== "" && mobile.trim() !== ""  && linkedin.trim() !== "";
         setButtonDisabled(!areFieldsFilled);
     }, [name, email, mobile, linkedin]);

     const handleAddTMC = async () => {
      setLoading(true);
      console.log("Selected TMC: ", selectedEntity)
      if (userDetails?.workspaceInfo?.id === selectedEntity) {
        return message.error("Cannot attach a TMC to itself, Please change selected workspace")
      }
      if (userDetails?.workspaceInfo?.entity_type === "INTERMEDIARY") {
        message.warning("Attaching TMC to an intermediary")
      }
      const payload = {
        workspaceId: userDetails?.workspaceInfo?.id,
        tmcID: selectedEntity,
        userId: userDetails?.userInfo?.id
      };
      try {
        const response = await apiPost(
          API_ENDPOINT_ONBOARDING_ADD_TMC_TO_WORKSPACE,
          payload
        );
  
        if (response.status) {
          message.success("TMC has been added");
          setUserDetails((prev:any)=>({
            ...prev,
            TMCList: response?.data
          }))
          navigate(-1);
          // onClose();
        }
      } catch (error) {
        message.error("Could not add TMC");
      } finally {
        setLoading(false);
      }
    };

 

  const handleCancel = () => {
    setOpen(false);
  };
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  // const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
  //   console.log('Success:', values);
  //   handleOk(values); // Close modal after successful form submission
  // };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
    <ArrowLeftOutlined style={{color:'#74003B', fontSize:'26px', padding:'15px 25px 0px',cursor: "pointer",}} onClick={handleGoBack}/>
      <div className="company-details-form">
      {/* <Modal
        title={<span style={{ fontSize: "24px", fontWeight: 'normal', marginBottom:"24px" }}>Invite TMC</span>}
        open={visible}
        onCancel={onClose}
        confirmLoading={confirmLoading}
        okButtonProps={{style:{background:'#74003B', borderColor:'#74003B'}}}
        cancelButtonProps={{ style : {color: "#74003B", border: "1px solid #74003B",} }}
         // Footer removed for form buttons
      > */}
        <Form
          name="basic"
          initialValues={{ remember: true }}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          fields={[
            {
              name: "Name",
              value: name,
            },
            {
              name: "Email",
              value: email,
            },
            {
              name: "Mobile",
              value: mobile,
            },
            {
              name: "Linkedin profile link",
              value: linkedin,
            },
          ]}
         > 
         <div className="form-header" > Add TMC </div>

          <Form.Item<FieldType>
            label="Name"
            name= "username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input 
            type="text"
            onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input 
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="Mobile"
            name="mobile"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input 
            type="text"
            onChange={(e) => setMobile(e.target.value)}
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="LinkedIn profile link"
            name="linkedin"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input 
            type="text"
            onChange={(e) => setLinkedin(e.target.value)}/>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button 
                type="primary" 
                htmlType="submit"
                onClick={handleAddTMC}
                disabled={isButtonDisabled}
                loading={isLoading}
                style={{
                backgroundColor: isButtonDisabled ? "#A8A8A8" : "#74003B",
                borderColor: isButtonDisabled ? "#A8A8A8" : "#74003B",
                width:'50%'
              }}
              >
                          
                 Submit
              </Button>
          </Form.Item>

          {/* <EntitySearch
          onSelect={(selected: string) => setSelectedEntity(selected)}
        />

        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 12 }}
        >
          <Button type="primary" onClick={handleAddTMC}>
            Add
          </Button>
        </div> */}
        
        </Form>
        </div>
      {/* </Modal> */}
    </>
  );
};

export default InviteTMCModal;