import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Input, message } from "antd";
import { API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE } from "app/shared/MasterLayout/masterlayout.constant";
import { API_ENDPOINT_META_USER_INFO } from "app/scenes/Auth/auth.constant";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { apiPost, apiGet } from "app/services/apiServices";
import { userInfo, operationTypeState, hardRefresh } from "app/config/States/users";
// import fetchUserDetails from 'app/shared/MasterLayout';
import ShieldIcon from "../../../../assets/images/shield-tick-safe (1).png";
import "./CompanyDetails.scss";

interface StepOneProps {
  onNext: () => void;
}

const CompanyDetails: React.FC<StepOneProps> = ({ onNext }) => {
  
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [PAN, setPAN] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  // const [hardRefreshState, setHardRefresh] = useRecoilState(hardRefresh);
  
  //  const [operationType, setOperationType] = useRecoilState(operationTypeState);
  const navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const [form] = Form.useForm();


  console.log("userdetailsL:", userDetails);
  
  useEffect(() => {
    // Prefill form fields with data from Recoil state

    setName(userDetails?.workspaceInfo?.company || "");
    setWebsite(userDetails?.workspaceInfo?.website || "");
    setPAN(userDetails?.workspaceInfo?.pan || "");
  }, [userDetails]);

  useEffect(() => {
    const areFieldsFilled =
      name.trim() !== "" && website.trim() !== "" && PAN.trim() !== "";
    setButtonDisabled(!areFieldsFilled);
  }, [name, website, PAN]);

  // const fetchUserDetails = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const response = await apiGet(API_ENDPOINT_META_USER_INFO);
  //     if (response.status) {
  //       // Update user details in Recoil state
  //       setUserDetails({
  //         ...response.data,
  //         currentWorkspace: response.data?.workspaceInfo,
  //       });

  //       // Update local storage with workspace and box folder information
  //       localStorage.setItem(
  //         "currentBoxId",
  //         response.data.workspaceInfo?.box_folder_id
  //       );
  //       localStorage.setItem(
  //         "currentWorkspaceId", 
  //         response.data?.workspaceInfo?.id
  //       );

  //       // Prefill form fields with fetched data
  //       setName(response.data?.workspaceInfo?.company || "");
  //       setWebsite(response.data?.workspaceInfo?.website || "");
  //       setPAN(response.data?.workspaceInfo?.pan || "");
  //     } else {
  //       message.error("Unable to fetch user details");
  //     }
  //   } catch (error) {
  //     message.error("An error occurred while fetching user details");
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []); // Empty dependency array as the function doesn't depend on external variables

  // // First useEffect for hardRefreshState
  // useEffect(() => {
  //   if (hardRefreshState) { 
  //     fetchUserDetails(); 
  //   } 
  // }, [hardRefreshState, fetchUserDetails]);

  // // Second useEffect for initial fetch
  // useEffect(() => {
  //   // Initial fetch when component loads
  //   fetchUserDetails();
  // }, [fetchUserDetails]);

  const isValidWebsite = (url: string) => {
    const websitePattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    return websitePattern.test(url);
  };

  const isValidPAN = (pan: string) => {
    const panPattern = /^[A-Z0-9]{10}$/i;
    return panPattern.test(pan);
  };

  const handleSubmit = async () => {
    if (!name.trim()) {
      message.error("Company name is required.");
      return;
    }

    // if (!isValidWebsite(website)) {
    //   message.error("Please enter a valid website (e.g., .com, .in).");
    //   return;
    // }

    if (!isValidPAN(PAN)) {
      message.error(
        "Please enter a valid PAN number (10 alphanumeric characters)."
      );
      return;
    }

    setLoading(true);
    // Handle form submission logic
    const operationType =
      userDetails?.workspaceList?.length > 0 ? "UPDATE" : "CREATE";

    const payload = {
      company: name,
      pan: PAN,
      website: website,
      // type: userDetails?.currentWorkspace?.type || userDetails?.workspaceInfo?.type,
      type : "PARTNER",
      entity_type :"CORPORATE",
      operationType: operationType,
    };

    const response = await apiPost(
      API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE,
      payload
    );

    console.log("Company Add Form Response: ", response)
    if (response.status) {
      // if (operationType !== "UPDATE") {
      const newWorkspace = response?.data
      // setOperationType('UPDATE');

      const newPanObj = {
        pan: response?.data.pan,
        entity_id: response?.data?.id,
        entity_name: response?.data?.company,
        last_updated: response?.data.last_updated,
        box_folder_id: response?.data.box_folder_id
      };
      // console.log("New WOrkspace: ", newWorkspace);
      // console.log("New PanObj: ", newPanObj);

      setUserDetails((prev: any) => ({
        ...prev,
        company: name,
        pan: PAN,
        website: website,
        pans: prev.pans?.some((pan: any) => pan.pan === newPanObj.pan)
        ? prev.pans?.map((pan:any) => 
            pan.pan === newPanObj.pan 
              ? {...pan, ...newPanObj }
              : pan)
        : [...(prev.pans || []), newPanObj],

        workspaceList: prev.workspaceList?.some(
          (workspace: any) => workspace.id === newWorkspace.id
        )
          ? prev.workspaceList.map((workspace: any) =>
              workspace.id === newWorkspace.id
                ? { ...workspace, ...newWorkspace }
                : workspace
            )
          : [...(prev.workspaceList || []), newWorkspace],
          
        workspaceInfo: {...prev.workspaceInfo, ...newWorkspace}
      }));


      console.log("Company Details Updated: ", userDetails)
      // setHardRefresh((prev)=> !prev);

      // } else {
      //   setUserDetails((prev: any) => ({
      //     ...prev,
      //     company: name,
      //     pan: PAN,
      //     website: website,
      //   }));
      // }
      // navigate("/app");
      form
        .validateFields()
        .then(() => {
          onNext(); // Trigger the next step
          message.success("Company details updated");
        })
        .catch((info) => {
          console.error("Validation Failed:", info);
        });
    } else if (!response.status) {
      // Handle Non unique company or pan name
      if (response?.error_code === "404") {
        console.log("WORKSPACE ALREADY EXISTS")
        message.error(`Workspace: ${response?.error} Name already exists, Please use a unique Workspace Name`);
      } else if (response?.error_code === "402") {
        console.log("PAN ALREADY EXISTS")
        message.error(`PAN: ${response?.error} already exists, Please enter Unique PAN`);
      }      
    } else {
      message.error("Could not update company details");
    }
    setLoading(false);
  };
  const handleSkip =()=>{
    onNext()
  }

  return (
    <div className="company-details-form">
      <Form
        form={form}
        layout="vertical"
        initialValues={{ name: name }}
        fields={[
          {
            name: "name",
            value: name,
          },
          {
            name: "Company Website",
            value: website,
          },
          {
            name: "PAN Number",
            value: PAN,
          },
        ]}
      >
        <div className="form-header">Enter Company Details</div>

        <div onClick={handleSkip} style={{fontStyle:'bold', cursor:'pointer'}}> SKIP </div>

        <Form.Item
          label="Company name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input
            type="text"
            defaultValue={name}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter company name"
            style={{ borderColor: "#C3C4CD" }}
          />
        </Form.Item>

        <Form.Item
          label="Company Website"
          name="Company Website"
          rules={[
            { required: true, message: "Please input your company website!" },
          ]}
        >
          <Input
            type="text"
            defaultValue={website}
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            placeholder="Enter company website"
            style={{ borderColor: "#C3C4CD" }}
          />
        </Form.Item>

        <Form.Item
          label="PAN Number"
          name="PAN Number"
          rules={[{ required: true, message: "Please input your PAN Number!" }]}
        >
          <Input
            type="text"
            defaultValue={PAN}
            value={PAN}
            onChange={(e) => setPAN(e.target.value)}
            placeholder="Enter PAN Number"
            style={{ borderColor: "#C3C4CD" }}
          />
        </Form.Item>

        <div className="info-text">
          <img src={ShieldIcon} alt="shield icon" />
          <p>No spams your information is safe with us!</p>
        </div>

        <Button
          type="primary"
          className="tax-manager-submit-button"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
          loading={isLoading}
          style={{
            backgroundColor: isButtonDisabled ? "#A8A8A8" : "#74003B",
            borderColor: isButtonDisabled ? "#A8A8A8" : "#74003B",
          }}
        >
          Next
        </Button>
      </Form>
    </div>
  );
};

export default CompanyDetails;
